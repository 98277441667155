import React from 'react'
import { Provider } from 'react-redux'
import store from '../../stores'
import {
  CardTypeMessage,
  CardTypeMessageContentLabelStyles,
  Company,
} from '../../types'
import App from './App'

interface Props {
  company: Company
  cardTypeMessage: CardTypeMessage
  cardTypeMessageContentLabelStyles: CardTypeMessageContentLabelStyles[]
}

const CardTypeMessageEditor = (props: Props) => {
  return (
    <Provider store={store}>
      <App
        company={props.company}
        cardTypeMessage={props.cardTypeMessage}
        cardTypeMessageContentLabelStyles={
          props.cardTypeMessageContentLabelStyles
        }
      />
    </Provider>
  )
}

export default CardTypeMessageEditor
