import React from 'react'
import { useAppSelector } from '../../hooks'
import { FieldArrayWithId, useWatch } from 'react-hook-form'
import { RootState } from 'components/Dashboard/stores'
import ContentForm from './ContentForm'
import { FormValues } from './ContentsFormRoot'
import LastCardForm from './LastCardForm'

interface Props {
  contentFields: FieldArrayWithId<FormValues, 'contents', 'id'>[]
}

const ContentsList = ({ contentFields }: Props) => {
  const { cardTypeMessage, displayContentIndex } = useAppSelector(
    (state: RootState) => state.cardTypeMessage
  )

  const lastCardLayout = useWatch<FormValues>({ name: 'lastCard.layout' })

  if (!cardTypeMessage) return <div />

  const lastCardActive = displayContentIndex === 'last'
  return (
    <div>
      {contentFields.map((item, index) => {
        const active = displayContentIndex === index
        return (
          <div
            key={item.id}
            style={{
              display: active ? 'block' : 'none',
            }}
          >
            <ContentForm contentIndex={index} layout={cardTypeMessage.layout} />
          </div>
        )
      })}
      {lastCardLayout !== 'none' && (
        <div
          style={{
            display: lastCardActive ? 'block' : 'none',
          }}
        >
          <LastCardForm />
        </div>
      )}
    </div>
  )
}

export default ContentsList
