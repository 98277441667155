import React, { useState, useEffect } from 'react'
import axios from 'axios'
import _ from 'lodash'
import styled from 'styled-components'
import { useModal } from 'react-hooks-use-modal'
import ReactLoading from 'react-loading'
import MessagePreview from '../organisms/PushMessage/MessagePreview'
import MessageObjectsEditor from '../organisms/PushMessage/MessageObjectsEditor'
import TriggerOrderTypeForm from 'components/organisms/PushMessage/TriggerOrderTypeForm'
import { includeOcDomainUri } from '../../lib/MessageObjectUtil'

const PushMessageTriggerForm = ({ company, trigger, ...props }) => {
  const formType = ['order', 'follow', 'ship'].includes(props.formType)
    ? props.formType
    : 'order'
  const [waitDays, setWaitDays] = useState(0)
  const [waitHours, setWaitHours] = useState(0)
  const [waitMinutes, setWaitMinutes] = useState(0)
  const [statusActive, setStatusActive] = useState(true)
  const [triggerType, setTriggerType] = useState()
  const [triggerValue, setTriggerValue] = useState('')

  const [messageObjects, setMessageObjects] = useState([])
  const [isSending, setIsSending] = useState(false)
  const [isSaved, setIsSaved] = useState(false)
  const [deletedMessageIds, setDeletedMessageIds] = useState({
    text: [],
    image: [],
  })

  const [Modal, openModal, closeModal] = useModal('form-root', {
    preventScroll: true,
    closeOnOverlayClick: false,
  })

  const isWelcomeMessage =
    formType === 'follow' && waitDays + waitHours + waitMinutes === 0

  useEffect(() => {
    const defaultObjects = [getDefaultMessageObject()]
    setMessageObjects(defaultObjects)

    if (trigger) {
      setTriggerType(trigger.triggerType)
      if (trigger.triggerValue) setTriggerValue(trigger.triggerValue)

      const totalMin = trigger.waitMinutes || 0
      setWaitDays(Math.floor(totalMin / 24 / 60))
      const r = totalMin % (24 * 60)
      setWaitHours(Math.floor(r / 60))
      setWaitMinutes(r % 60)

      setStatusActive(trigger.status === 'active')

      if (trigger.pushMessageObjects.length > 0) {
        setMessageObjects(
          trigger.pushMessageObjects.map(obj => {
            if (obj.type === 'image' && obj.uri) obj.type = 'imageWithLink'
            obj.identifier = `${obj.type}-${obj.id}`
            return obj
          })
        )
      }
    } else {
      if (formType === 'ship') {
        setTriggerType('order_shipped')
      } else if (formType === 'follow') {
        setTriggerType('line_follow')
        // 新規作成 かつ line_follow はあいさつメッセージ新規登録で確定
        setMessageObjects([
          {
            type: 'text',
            order: 0,
            text: '{Nickname}さん、はじめまして！\n友だち追加ありがとうございます。{AccountName}です。\n\nショップでのお買い物はこちらから → {ShopUrl}',
            identifier: (Math.random() + 1).toString(36).substring(7),
          },
        ])
      } else {
        setTriggerType('all_order')
      }
    }
  }, [])

  const availableKeywords = ['Nickname', 'AccountName', 'ShopUrl']
  if (
    company.shopAccountType === 'base' &&
    (formType === 'order' || formType === 'ship')
  )
    availableKeywords.push('ReviewUrl')
  if (company.shopAccountType === 'makeshop' && triggerType === 'order_shipped')
    availableKeywords.push('SlipNumber')

  const orderedMessageObjects = _.sortBy(messageObjects, 'order')

  const getDefaultMessageObject = () => ({
    type: null,
    order:
      messageObjects.length > 0
        ? (orderedMessageObjects.slice(-1)[0].order || 0) + 1
        : 0,
    identifier: (Math.random() + 1).toString(36).substring(7),
  })

  const addDefaultMessageObject = () => {
    if (messageObjects.length >= 5) return

    setMessageObjects([messageObjects, getDefaultMessageObject()].flat())
  }

  const deletePersistedObject = deletedObject => {
    if (!deletedObject.id) return

    const newIds = Object.assign({}, deletedMessageIds)
    if (deletedObject.type === 'text') {
      newIds.text.push(deletedObject.id)
    } else {
      newIds.image.push(deletedObject.id)
    }
    setDeletedMessageIds(newIds)
  }

  const isValid = obj => {
    if (obj.type === 'text') {
      return obj.text !== ''
    }
    const isImageValid = !_.isNil(obj.file) || obj.imageSrc
    if (obj.type === 'image') {
      return isImageValid
    }
    if (obj.type === 'imageWithLink') {
      return (
        isImageValid && obj.uri && !(obj.altText && obj.altText.length > 100)
      )
    }
    return false
  }

  const validMessageObjects = orderedMessageObjects.filter(obj => isValid(obj))

  const messageUriValid = !includeOcDomainUri(orderedMessageObjects)

  const handleChangeMessageObjects = newObjects => {
    if (newObjects.length === 0) {
      setMessageObjects([getDefaultMessageObject()])
    } else {
      setMessageObjects(newObjects)
    }
  }

  const handleClickConfirmButton = () => {
    if (isWelcomeMessage) handleSubmit()
    openModal()
  }

  const handleSubmit = () => {
    if (isSending || !messageUriValid) return

    if (
      (triggerType.includes('item') || triggerType.includes('category')) &&
      !triggerValue
    )
      return

    setIsSending(true)
    const formData = new FormData()
    formData.append('authenticity_token', $('#authenticity_token').val())
    formData.append('deleted_message_object_ids[text]', deletedMessageIds.text)
    formData.append(
      'deleted_message_object_ids[image]',
      deletedMessageIds.image
    )
    formData.append(
      'push_message_trigger[wait_minutes]',
      (waitDays * 24 + waitHours) * 60 + waitMinutes
    )
    formData.append('push_message_trigger[trigger_type]', triggerType)
    if (triggerValue)
      formData.append('push_message_trigger[trigger_value]', triggerValue)
    formData.append(
      'push_message_trigger[status]',
      statusActive || (isWelcomeMessage && !company.isSelfLine)
        ? 'active'
        : 'inactive'
    )
    const textMessageObjects = _.filter(
      validMessageObjects,
      obj => obj.type === 'text'
    )
    textMessageObjects.forEach((obj, index) => {
      const parent =
        'push_message_trigger[push_message_text_objects_attributes]'
      formData.append(`${parent}[${index}][text]`, obj.text)
      formData.append(`${parent}[${index}][order]`, obj.order)
      if (obj.id) formData.append(`${parent}[${index}][id]`, obj.id)
    })
    const imageMessageObjects = _.filter(
      validMessageObjects,
      obj => obj.type === 'image' || obj.type === 'imageWithLink'
    )
    imageMessageObjects.forEach((obj, index) => {
      const parent =
        'push_message_trigger[push_message_image_objects_attributes]'
      if (obj.file) formData.append(`${parent}[${index}][image]`, obj.file)
      formData.append(`${parent}[${index}][uri]`, obj.uri || '')
      formData.append(`${parent}[${index}][alt_text]`, obj.altText || '')
      formData.append(`${parent}[${index}][order]`, obj.order)
      if (obj.id) formData.append(`${parent}[${index}][id]`, obj.id)
    })

    if (trigger) {
      const url = `/api/companies/${company.slug}/push_message_triggers/${trigger.id}`
      axios.put(url, formData).then(_response => {
        setIsSaved(true)
        setIsSending(false)
      })
    } else {
      const url = `/api/companies/${company.slug}/push_message_triggers`
      axios.post(url, formData).then(_response => {
        setIsSaved(true)
        setIsSending(false)

        const gaEvent = isWelcomeMessage
          ? 'user_greet_msg_registered'
          : 'user_trigger_msg_registered'
        window.dataLayer.push({ event: gaEvent })
      })
    }
  }

  const ConfirmModal = (
    <Modal>
      <ModalInner style={{ maxWidth: window.innerWidth - 30 }}>
        {(() => {
          if (isSaved) {
            return (
              <div className="uk-text-center">
                <h4>登録が完了しました！</h4>
                <div>
                  {isWelcomeMessage ? (
                    <a
                      className="uk-button uk-button-primary"
                      href={`/companies/${company.slug}/dashboard`}
                    >
                      ダッシュボードトップへ
                    </a>
                  ) : (
                    <a
                      className="uk-button uk-button-primary"
                      href={`/companies/${company.slug}/push_message_triggers`}
                    >
                      メッセージ配信トップへ
                    </a>
                  )}
                </div>
              </div>
            )
          } else if (isSending) {
            return (
              <>
                <h4 className="uk-text-center">
                  メッセージ内容を登録中です...
                </h4>
                <div className="uk-section">
                  <ReactLoading
                    className="uk-margin-auto"
                    type={'spin'}
                    color={'#00888d'}
                    height={100}
                    width={100}
                  />
                </div>
              </>
            )
          } else {
            return (
              <>
                <h4 className="uk-text-center">登録完了まであと少しです！</h4>
                <div className="uk-text-center uk-margin">
                  <img
                    className="uk-width-1-2 uk-width-1-3@s"
                    src="https://omiseconnect-assets.s3.ap-northeast-1.amazonaws.com/images/dashboard/push_message/sending.png"
                  />
                </div>
                <div className="uk-text-small">
                  下記の内容でメッセージを登録してよろしいですか？
                </div>
                <ConfirmModalTable className="uk-text-small uk-margin-small-top uk-margin-bottom">
                  <ConfirmModalTableContent className="uk-width-1-4 uk-text-center">
                    送信タイミング
                  </ConfirmModalTableContent>
                  <ConfirmModalTableContent className="uk-width-3-4">
                    {
                      {
                        order: '商品購入',
                        follow: '友だち追加',
                        ship: '商品配送',
                      }[formType]
                    }
                    {waitDays + waitHours + waitMinutes === 0
                      ? '後すぐ'
                      : `から${waitDays > 0 ? `${waitDays}日` : ''}${
                          waitHours > 0 ? `${waitHours}時間` : ''
                        }${waitMinutes > 0 ? `${waitMinutes}分` : ''}後`}
                  </ConfirmModalTableContent>
                </ConfirmModalTable>
                <div className="uk-grid uk-grid-small uk-child-width-1-4@s uk-child-width-1-2 uk-flex-center">
                  <div>
                    <button
                      className="uk-button uk-button-default uk-modal-close uk-width-1-1"
                      type="button"
                      onClick={closeModal}
                    >
                      入力へ戻る
                    </button>
                  </div>
                  <div>
                    <div
                      className="uk-button uk-button-primary uk-width-1-1"
                      onClick={handleSubmit}
                    >
                      登録する
                    </div>
                  </div>
                </div>
              </>
            )
          }
        })()}
      </ModalInner>
    </Modal>
  )

  const errorMessage = (() => {
    if (triggerType) {
      if (triggerType.includes('item') && !triggerValue)
        return '対象の商品を選択してください'
      if (triggerType.includes('category') && !triggerValue)
        return '対象のカテゴリーを選択してください'
    }
    if (validMessageObjects.length === 0)
      return '吹き出しを1つ以上登録してください'
    if (!messageUriValid)
      return '「omiseconnect.jp」が含まれるURLを指定することはできません。'
  })()

  return (
    <div id="form-root">
      {isWelcomeMessage ? (
        <>
          <div className="uk-text-muted uk-text-small uk-margin-bottom message-editor-width">
            あいさつメッセージには「お客さまのLINE名」やクーポンコードを入れるとより効果的です。
            <br />
            詳しくは
            <a
              href={`/usage_guide_redirects/case_study_greeting_message?shop_account_type=${company.shopAccountType}`}
              target="_blank"
            >
              {' '}
              あいさつメッセージの活用術{' '}
              <div
                className="material-icons"
                style={{ fontSize: 16, verticalAlign: 'text-bottom' }}
              >
                open_in_new
              </div>
            </a>
            をご確認ください。
          </div>
          {company.isSelfLine && (
            <div className="uk-alert-danger uk-alert uk-text-small message-editor-width">
              LINE Official Account
              Managerであいさつメッセージを設定している場合におみせコネクト上でもあいさつメッセージを設定するとメッセージが重複して送信されます。
              <br />
              LINE Official Account
              Manager側の設定をOFFにすることをおすすめします。
            </div>
          )}
        </>
      ) : (
        <>
          <div className="uk-section-xsmall">
            <div className="uk-form-label uk-text-bold">
              配信タイミング
              <span className="required-icon">必須</span>
            </div>
            <div className="uk-margin-small-top uk-text-small">
              <div className="uk-display-inline-block uk-margin-small-right">
                {
                  {
                    order: '商品購入の',
                    follow: '友だち追加の',
                    ship: '商品配送の',
                  }[formType]
                }
              </div>
              <div className="uk-display-inline-block">
                <div className="uk-flex uk-flex-middle">
                  <WaitTimeSelect
                    className="uk-select"
                    value={waitDays}
                    onChange={e => setWaitDays(Number(e.currentTarget.value))}
                  >
                    {[...Array(100).keys()].map(i => (
                      <option value={i} key={i}>
                        {i}
                      </option>
                    ))}
                  </WaitTimeSelect>
                  <div className="uk-padding-small">日</div>
                  <WaitTimeSelect
                    className="uk-select"
                    value={waitHours}
                    onChange={e => setWaitHours(Number(e.currentTarget.value))}
                  >
                    {[...Array(24).keys()].map(i => (
                      <option value={i} key={i}>
                        {i}
                      </option>
                    ))}
                  </WaitTimeSelect>
                  <div className="uk-padding-small">時間</div>
                  <WaitTimeSelect
                    className="uk-select"
                    value={waitMinutes}
                    onChange={e =>
                      setWaitMinutes(Number(e.currentTarget.value))
                    }
                  >
                    {[...Array(12).keys()].map(i => (
                      <option value={i * 5} key={i}>
                        {i * 5}
                      </option>
                    ))}
                  </WaitTimeSelect>
                  <div className="uk-padding-small">分</div>
                </div>
              </div>
              <div className="uk-display-inline-block">
                後に下記メッセージを自動で送る
              </div>
              {formType === 'ship' && (
                <div className="uk-margin uk-text-small uk-text-muted">
                  ※ショップ管理画面で注文を「発送済み」に変更したタイミングが基準となります。
                  <br />※
                  仕様上の都合により、発送済みに変更してから最大で1時間程度メッセージ配信に時間がかかる場合がございます。
                </div>
              )}
            </div>
          </div>
        </>
      )}
      {['order', 'ship'].includes(formType) && (
        <TriggerOrderTypeForm
          company={company}
          type={triggerType}
          value={triggerValue}
          formType={formType}
          setType={setTriggerType}
          setValue={setTriggerValue}
        />
      )}
      <div className="uk-section-xsmall message-editor-width">
        <div className="uk-form-label uk-text-bold uk-margin-small-bottom">
          メッセージ内容
        </div>
        {!isWelcomeMessage && (
          <div className="uk-text-muted uk-text-xsmall uk-margin-bottom">
            複数の吹き出しを登録した場合でも、送信数は1人あたり1通として計算されます。
          </div>
        )}
        <MessageObjectsEditor
          messageObjects={orderedMessageObjects}
          onChange={handleChangeMessageObjects}
          deletePersistedObject={deletePersistedObject}
          addMessageObject={addDefaultMessageObject}
          company={company}
          availableKeywords={availableKeywords}
        />
      </div>
      <div className="uk-section-xsmall">
        {(!isWelcomeMessage || company.isSelfLine) && (
          <label className="uk-flex uk-flex-center uk-flex-middle uk-margin-bottom">
            <input
              type="checkbox"
              checked={statusActive}
              onChange={_e => setStatusActive(!statusActive)}
              className="uk-checkbox"
              style={{ margin: '0 5px 0 0' }}
            />
            <div className="uk-text-small">有効にする</div>
          </label>
        )}
        <div className="uk-text-center">
          {!_.isNil(errorMessage) && (
            <div className="uk-text-small uk-text-danger uk-margin-small-bottom">
              {errorMessage}
            </div>
          )}
          <button
            className="uk-button uk-button-large uk-button-primary"
            onClick={handleClickConfirmButton}
            disabled={!_.isNil(errorMessage)}
          >
            メッセージを登録する
          </button>
        </div>
        {ConfirmModal}
      </div>
      <MessagePreview messageObjects={validMessageObjects} company={company} />
    </div>
  )
}
export default PushMessageTriggerForm

const ModalInner = styled.div`
  background-color: #fff;
  padding: 30px 25px;
  width: 600px;
  box-sizing: border-box;
`
const ConfirmModalTable = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid;
  border-left: 1px solid;
`
const WaitTimeSelect = styled.select`
  height: 30px !important;
  width: 60px;
`
const ConfirmModalTableContent = styled.div`
  border-bottom: 1px solid;
  border-right: 1px solid;
  padding: 8px;
  display: flex;
  align-items: center;
`
