export const includeOcDomainUri = messages =>
  messages.some(obj => {
    if (obj.type === 'text') {
      return obj.text && obj.text.match(/omiseconnect\.jp/)
    }
    if (obj.type === 'imageWithLink') {
      return obj.uri && obj.uri.match(/omiseconnect\.jp/)
    }
    return false
  })
