import React, { useState } from 'react'
import styled from 'styled-components'

const MessagePreview = ({ company, messageObjects }) => {
  const [open, setOpen] = useState(window.innerWidth > 600)
  const previewHeight = Math.min(window.innerHeight - 60, 540)
  return (
    <PreviewWrapper style={{ marginBottom: open ? 0 : previewHeight * -1 }}>
      <PreviewHeader>
        <PreviewHeaderTitle onClick={() => setOpen(!open)}>
          <span className="material-icons">
            {open ? 'arrow_drop_down' : 'arrow_drop_up'}
          </span>
          プレビュー
        </PreviewHeaderTitle>
      </PreviewHeader>
      <PreviewBody style={{ height: previewHeight }}>
        {messageObjects.map(obj => {
          return (
            <PreviewLow key={obj.identifier}>
              <PreviewIcon
                src={
                  company.companyLineAccount.pictureUrl ||
                  'https://omiseconnect-assets.s3.ap-northeast-1.amazonaws.com/images/line_account/line_default_icon.jpg'
                }
              />
              {(() => {
                if (obj.type === 'text') {
                  const text = obj.text
                    .replace(/{Nickname}/g, '太郎')
                    .replace(/{AccountName}/g, company.shopAccount.shopName)
                    .replace(/{ShopUrl}/g, ` ${company.shopAccount.shopUrl} `)
                    .replace(
                      /{ReviewUrl}/g,
                      'https://c.thebase.in/dynamic_receipts'
                    )
                    .replace(/{SlipNumber}/g, '123456789')
                  return (
                    <TextPreview>
                      <TextPreviewContent>
                        {text
                          .split(
                            /(https?:\/\/[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#]+|\n)/
                          )
                          .map((str, index) => (
                            <React.Fragment key={index}>
                              {(() => {
                                if (str.match(/\n/)) {
                                  return <br />
                                } else if (
                                  str.match(
                                    /^https?:\/\/[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#]+/
                                  )
                                ) {
                                  return (
                                    <a href={str} target="_blank">
                                      {str}
                                    </a>
                                  )
                                } else {
                                  return str
                                }
                              })()}
                            </React.Fragment>
                          ))}
                      </TextPreviewContent>
                    </TextPreview>
                  )
                }
                if (obj.type === 'image') {
                  const src =
                    obj.imageSrc ||
                    (
                      (window.URL || window.webkitURL).createObjectURL ||
                      window.createObjectURL
                    )(obj.file)
                  return <ImagePreview src={src} />
                }
                if (obj.type === 'imageWithLink') {
                  const src =
                    obj.imageSrc ||
                    (
                      (window.URL || window.webkitURL).createObjectURL ||
                      window.createObjectURL
                    )(obj.file)
                  const uri = obj.uri.match(
                    /^https?:\/\/[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#]+/
                  )
                    ? obj.uri
                    : null
                  return uri ? (
                    <a href={uri} target="_blank">
                      <ImageWithLinkPreview src={src} />
                    </a>
                  ) : (
                    <ImageWithLinkPreview src={src} />
                  )
                }
              })()}
            </PreviewLow>
          )
        })}
      </PreviewBody>
    </PreviewWrapper>
  )
}

export default MessagePreview

const PreviewWrapper = styled.div`
  position: fixed;
  right: 1rem;
  bottom: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 1rem 0 rgb(0 0 0 / 50%);
  transition: margin 0.3s ease-in-out;
  border-radius: 2px;
  @media screen and (max-width: 600px) {
    right: calc((100% - 340px) / 2);
  }
`
const PreviewHeader = styled.div`
  padding: 12px 20px;
  background-color: #253048;
  color: #fff;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
`
const PreviewHeaderTitle = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;

  span {
    font-size: 25px;
    padding-bottom: 2px;
    margin-right: 2px;
  }
`
const PreviewBody = styled.div`
  background-color: #88a6d2;
  width: 370px;
  overflow-y: scroll;
  padding: 20px 12px;
  box-sizing: border-box;

  @media screen and (max-width: 600px) {
    width: 340px;
  }
`
const PreviewLow = styled.div`
  position: relative;
  margin-bottom: 8px;
`
const PreviewIcon = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  object-fit: cover;
`
const TextPreview = styled.div`
  margin-left: 44px;
  background: #f2f3f5;
  position: relative;
  display: inline-block;
  padding: 8px 12px;
  font-size: 14px;
  border-radius: 16px;
  word-break: break-word;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0.75rem;
    width: 0;
    height: 0;
    border: 0.5rem solid transparent;
    border-right-color: #edeff0;
    border-left: 0;
    border-top: 0;
    margin-left: -0.375rem;
  }
`
const TextPreviewContent = styled.div`
  a {
    color: #4e73cb;
    text-decoration: underline;
    cursor: pointer;
  }
`
const ImagePreview = styled.img`
  width: 200px;
  border-radius: 10px;
  background-color: #fff;
  margin-left: 44px;
`

const ImageWithLinkPreview = styled.img`
  width: 100%;
  border-radius: 4px;
  background-color: #fff;
  margin-top: 40px;
`
