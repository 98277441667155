import React from 'react'
import {
  UseFieldArrayAppend,
  FieldArrayWithId,
  UseFieldArrayRemove,
  UseFieldArrayMove,
  useFormContext,
  UseFieldArrayInsert,
} from 'react-hook-form'
import { useAppDispatch, useAppSelector } from 'components/Dashboard/hooks'
import { RootState } from 'components/Dashboard/stores'
import { defaultContentFormValue, FormValues } from './ContentsFormRoot'
import styled from 'styled-components'
import { setDisplayContentIndex } from 'components/Dashboard/stores/cardTypeMessage'

interface Props {
  contentFields: FieldArrayWithId<FormValues, 'contents', 'id'>[]
  appendContent: UseFieldArrayAppend<FormValues, 'contents'>
  removeContent: UseFieldArrayRemove
  moveContent: UseFieldArrayMove
  insertContent: UseFieldArrayInsert<FormValues, 'contents'>
}

const MAX_CONTENTS_SIZE = 9

const ContentsController = ({
  contentFields,
  appendContent,
  removeContent,
  moveContent,
  insertContent,
}: Props) => {
  const dispatch = useAppDispatch()
  const { displayContentIndex } = useAppSelector(
    (state: RootState) => state.cardTypeMessage
  )

  const { getValues, setValue } = useFormContext<FormValues>()
  const lastCardLayout = getValues('lastCard.layout')

  const apendContent = () => {
    if (contentFields.length >= MAX_CONTENTS_SIZE) return

    const index = contentFields.length
    appendContent(defaultContentFormValue)
    dispatch(setDisplayContentIndex(index))
  }

  const handleClickRemoveContentButton = () => {
    if (contentFields.length <= 1 || displayContentIndex === 'last') return

    if (
      window.confirm(
        'カードを削除しますか？（一度削除したカードは復元できません）'
      )
    ) {
      const removeContentDbid = getValues(
        `contents.${displayContentIndex}.dbid`
      )
      if (removeContentDbid) {
        const removedContentIds = getValues('removedContentIds')
        setValue(
          'removedContentIds',
          removedContentIds.concat(removeContentDbid)
        )
      }
      removeContent(displayContentIndex)
      if (displayContentIndex === contentFields.length - 1) {
        dispatch(setDisplayContentIndex(displayContentIndex - 1))
      }
    }
  }

  const handleClickMoveContentButton = (newIndex: number) => {
    if (displayContentIndex === 'last') return

    moveContent(displayContentIndex, newIndex)
    dispatch(setDisplayContentIndex(newIndex))
  }

  const changeDisplayContentIndex = (contentOrder: number | 'last') => {
    dispatch(setDisplayContentIndex(contentOrder))
  }

  const duplicateContent = () => {
    if (
      contentFields.length >= MAX_CONTENTS_SIZE ||
      displayContentIndex === 'last'
    )
      return

    const content = getValues(`contents.${displayContentIndex}`)
    const newIndex = displayContentIndex + 1
    insertContent(newIndex, { ...content, dbid: null })
    dispatch(setDisplayContentIndex(newIndex))
  }

  const addLastCard = () => {
    setValue('lastCard.layout', 'simple')
    dispatch(setDisplayContentIndex('last'))
  }

  const removeLastCard = () => {
    if (window.confirm('もっと見るカードを削除しますか？')) {
      setValue('lastCard.layout', 'none')
      dispatch(setDisplayContentIndex(contentFields.length - 1))
    }
  }

  return (
    <div className="uk-position-relative">
      <ul
        className="uk-flex uk-padding-remove"
        style={{
          listStyle: 'none',
          borderBottom: '1px solid #e5e5e5',
          minHeight: 34,
        }}
      >
        {contentFields.map((_, index) => (
          <ListItem
            key={index}
            active={displayContentIndex === index}
            onClick={() => changeDisplayContentIndex(index)}
          >
            {index + 1}
          </ListItem>
        ))}
        {lastCardLayout !== 'none' && (
          <ListItem
            active={displayContentIndex === 'last'}
            onClick={() => changeDisplayContentIndex('last')}
          >
            もっと見る
          </ListItem>
        )}
      </ul>
      <div
        className="uk-position-absolute uk-position-top-right"
        style={{ marginTop: -5 }}
      >
        {contentFields.length < MAX_CONTENTS_SIZE && (
          <button
            className="uk-button uk-button-default uk-button-small"
            onClick={apendContent}
          >
            カードを追加
          </button>
        )}
        {lastCardLayout === 'none' && (
          <button
            className="uk-button uk-button-default uk-button-small uk-margin-small-left"
            onClick={addLastCard}
          >
            もっと見るカードを追加
          </button>
        )}
      </div>
      <div className="uk-grid uk-grid-xsmall uk-flex-right">
        {displayContentIndex === 'last' ? (
          <div>
            <button
              className="uk-button uk-button-small"
              onClick={removeLastCard}
            >
              削除
            </button>
          </div>
        ) : (
          <>
            {contentFields.length < MAX_CONTENTS_SIZE && (
              <div>
                <button
                  className="uk-button uk-button-small"
                  onClick={duplicateContent}
                >
                  複製
                </button>
              </div>
            )}
            {displayContentIndex > 0 && (
              <div>
                <button
                  className="uk-button uk-button-small"
                  onClick={() =>
                    handleClickMoveContentButton(displayContentIndex - 1)
                  }
                >
                  前に移動
                </button>
              </div>
            )}
            {displayContentIndex < contentFields.length - 1 && (
              <div>
                <button
                  className="uk-button uk-button-small"
                  onClick={() =>
                    handleClickMoveContentButton(displayContentIndex + 1)
                  }
                >
                  後に移動
                </button>
              </div>
            )}
            {contentFields.length > 1 && (
              <div>
                <button
                  className="uk-button uk-button-small"
                  onClick={handleClickRemoveContentButton}
                >
                  削除
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

const ListItem = styled.li<{ active: boolean }>`
  padding: 4px 16px;
  box-shadow: ${props => (props.active ? '0 1px #fff' : 'none')};
  border: ${props => (props.active ? '1px solid #e5e5e5' : 'none')};
  border-bottom: none;
  cursor: ${props => (props.active ? 'unset' : 'pointer')};
  &:hover {
    box-shadow: ${props =>
      props.active ? '1px solid #e5e5e5' : '0 0 1px #ccc'};
  }
`

export default ContentsController
