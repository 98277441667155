import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import SelectRichmenuLayouts from './SelectRichmenuLayouts'
import RichmenuActionEdit from './RichmenuActionEdit'
import ImageDropInput from '../../../molecules/ImageDropInput'
import { RichMenuTemplates } from '../../const'
import { Company, RichMenuData } from '../../types'

interface Props {
  richMenuData: RichMenuData | null
  action: string
  setSendFile(file: File): void
  company: Company
}

const UploadRichmenuImages = ({
  richMenuData,
  action,
  setSendFile,
  company,
}: Props) => {
  const [file, setFile] = useState<File | null>(null)
  const [imageUrl, setImageUrl] = useState('')
  const [imgHeight, SetImgHeight] = useState(0)
  const [isLarge, setIsLarge] = useState<boolean | null>(null)
  const [templateId, setTemplateId] = useState<string | null>('')
  const template = RichMenuTemplates.find(
    template => template.id === templateId
  )

  useEffect(() => {
    setImageUrl(file ? window.URL.createObjectURL(file) : '')
  }, [file])

  useEffect(() => {
    if (imageUrl.length > 0) {
      const minHeight = 250
      const img = new Image()
      img.src = imageUrl
      img.onload = () => {
        if (img.height >= minHeight * 2) {
          setIsLarge(true)
        } else {
          setIsLarge(false)
        }
        aspectRatioSetting(img)
      }
    }
  }, [imageUrl])

  useEffect(() => {
    if (action === 'edit' && richMenuData) {
      if (imageUrl.length === 0 && Object.keys(richMenuData).length > 0) {
        setImageUrl('data:image/png;base64,' + richMenuData.imgBinary)
        setFile(convertToFile(String(richMenuData.imgBinary)))
        setSendFile(convertToFile(String(richMenuData.imgBinary)))
      }
      if (imageUrl.length === 0 && Object.keys(richMenuData).length > 0) {
        setTemplateId(richMenuData.templateType)
      }
    }
  }, [richMenuData])

  const onChangeFile = async (file: File | null) => {
    if (file) {
      validateImgFile(file)
    } else {
      setFile(null)
    }
  }

  const validateImgFile = (validationFile: File) => {
    if (validationFile) {
      const sizeLimit = 1024 * 1024 * 1
      if (validationFile.size > sizeLimit) {
        window.alert('ファイルサイズが1MBを越えています。')
        return
      } else {
        const fileUrl = window.URL.createObjectURL(validationFile)
        const validateAspect = 1.45
        const img = new Image()
        img.src = fileUrl
        img.onload = () => {
          if (img.width / img.height < validateAspect) {
            window.alert(
              '画像のアスペクト比（幅/高さ）が1.45以下です。横長の画像をアップロードしてください。'
            )
            return
          } else if (800 > img.width) {
            window.alert('画像の幅のサイズが800px未満です。')
            return
          } else if (img.width > 2500) {
            window.alert('画像の幅サイズが2500px超過です。')
            return
          } else if (250 > img.height) {
            window.alert('画像の高さサイズが250px未満です。')
            return
          } else {
            setFile(validationFile)
            setSendFile(validationFile)
          }
        }
      }
    }
  }

  const aspectRatioSetting = (img: HTMLImageElement) => {
    const orgWidth = img.width
    const orgHeight = img.height
    img.width = 600
    img.height = orgHeight * (img.width / orgWidth)
    SetImgHeight(img.height)
  }

  const convertToFile = (imgUrl: string) => {
    const blob = atob(imgUrl.replace(/^.*,/, ''))
    const buffer = new Uint8Array(blob.length)
    for (let i = 0; i < blob.length; i++) {
      buffer[i] = blob.charCodeAt(i)
    }
    return new File([buffer.buffer], 'edit_rich_menu_image.png', {
      type: 'image/png',
    })
  }

  return (
    <>
      <h4 className="uk-margin-large-top">3.画像の登録・レイアウトの選択</h4>
      リッチメニュー画像<span className="required-icon">必須</span>
      <br />
      <div className="uk-margin-top uk-margin-bottom">
        <div>
          {imageUrl && (
            <TemplateImage imgHeight={imgHeight}>
              <ImagePreview src={imageUrl} imgHeight={imgHeight} />
              {template && (
                <TemplateGridWrapper>
                  {template.areas.map(({ x, y }) => (
                    <TemplateGridItem
                      key={`${x}-${y}`}
                      style={{
                        width: `${(100 * x.length) / template.columns}%`,
                        height: `${(100 * y.length) / template.rows}%`,
                      }}
                    />
                  ))}
                </TemplateGridWrapper>
              )}
            </TemplateImage>
          )}
          {file ? (
            <div className="uk-width-3-4@s uk-margin-auto uk-text-center">
              <div
                className="uk-button uk-button-default uk-button-small uk-margin-top"
                onClick={() => onChangeFile(null)}
              >
                画像を変更する
              </div>
            </div>
          ) : (
            <ImageDropInput onChange={(file: File) => onChangeFile(file)} />
          )}
        </div>
      </div>
      <div>
        <SelectRichmenuLayouts
          imageUrl={imageUrl}
          setTemplateId={setTemplateId}
          templateId={templateId}
          isLarge={isLarge}
          action={action}
        />
      </div>
      <div>
        <RichmenuActionEdit
          templateId={templateId}
          imageUrl={imageUrl}
          richMenuData={richMenuData}
          action={action}
          company={company}
        />
      </div>
    </>
  )
}

export default UploadRichmenuImages

const ImagePreview = styled.img<{ imgHeight: number }>`
  width: 600px;
  height: ${({ imgHeight }) => imgHeight}px;
  border: 1px solid #eee;
  @media screen and (max-width: 600px) {
    width: 100%;
    height: 100%;
  }
`
const TemplateImage = styled.div<{ imgHeight: number }>`
  width: 600px;
  height: ${({ imgHeight }) => imgHeight}px;
  position: relative;
  @media screen and (max-width: 600px) {
    width: 100%;
    height: 100%;
  }
`

const TemplateGridWrapper = styled.div`
  position: absolute;
  inset: 0;
`
const TemplateGridItem = styled.div`
  border: 2px solid #00b900;
  box-sizing: border-box;
  float: left;
`
