import { combineReducers } from 'redux'
import { configureStore } from '@reduxjs/toolkit'

import companyReducer from './company'
import pvRetargetingTrigger from './pvRetargetingTrigger'
import cartAbandonmentTrigger from './cartAbandonmentTrigger'
import shopItem from './shopItem'
import flexMessageButtonTheme from './flexMessageButtonTheme'
import restockTrigger from './restockTrigger'
import restockNotificationSetting from './restockNotificationSetting'
import restockNotificationVariantData from './restockNotificationVariantData'
import cardTypeMessage from './cardTypeMessage'

const reducer = combineReducers({
  company: companyReducer,
  pvRetargetingTrigger: pvRetargetingTrigger,
  cartAbandonmentTrigger: cartAbandonmentTrigger,
  shopItem: shopItem,
  flexMessageButtonTheme: flexMessageButtonTheme,
  restockTrigger: restockTrigger,
  restockNotificationSetting: restockNotificationSetting,
  restockNotificationVariantData: restockNotificationVariantData,
  cardTypeMessage: cardTypeMessage,
})

const store = configureStore({ reducer })

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
