import React, { useEffect } from 'react'
import {
  useForm,
  FormProvider,
  useFieldArray,
  FieldErrors,
} from 'react-hook-form'
import ContentsList from './ContentsList'
import CardPreview from './CardPreview'
import ContentsController from './ContentsController'
import { useAppDispatch, useAppSelector } from 'components/Dashboard/hooks'
import { RootState } from 'components/Dashboard/stores'
import {
  saveCardTypeMessageAllCards,
  setDisplayContentIndex,
} from 'components/Dashboard/stores/cardTypeMessage'
import UIkit from 'uikit'
import _ from 'lodash'
import { CardTypeMessage } from 'components/Dashboard/types'

export type FormValues = {
  contents: ContentFormValue[]
  lastCard: {
    layout: CardTypeMessage['lastCardLayout']
    text: CardTypeMessage['lastCardText']
    url: CardTypeMessage['lastCardUrl']
    imageBlob: Blob | null
    imageUrl: string | null
  }
  removedContentIds: number[]
}

export type ContentFormValue = {
  dbid: number | null
  actionButtonText: string
  actionButtonUrl: string
  description: string
  displayActionButton: boolean
  displayDescription: boolean
  displayLabel: boolean
  displayPrice: boolean
  image: {
    blob: Blob | null
    url: string | null
    originalIdentifier: string | null // NOTE: 既存データから複製した際に、既存データのimageカラムの値を保持
  }
  labelStyle: number
  labelText: string
  price: string
  title: string
}

export const defaultContentFormValue: ContentFormValue = {
  dbid: null,
  actionButtonText: '',
  actionButtonUrl: '',
  description: '',
  displayActionButton: true,
  displayDescription: true,
  displayLabel: true,
  displayPrice: true,
  image: {
    blob: null,
    url: null,
    originalIdentifier: null,
  },
  labelStyle: 0,
  labelText: '',
  price: '',
  title: '',
}

const ContentsFormRoot = () => {
  const dispatch = useAppDispatch()
  const { cardTypeMessage, cardTypeMessageContents } = useAppSelector(
    (state: RootState) => state.cardTypeMessage
  )

  const methods = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      lastCard: {},
      removedContentIds: [],
    },
  })
  const { control, handleSubmit, setValue, getValues } = methods
  const {
    fields: contentFields,
    append: appendContent,
    remove: removeContent,
    move: moveContent,
    insert: insertContent,
  } = useFieldArray({
    control,
    name: 'contents',
  })

  useEffect(() => {
    if (!cardTypeMessage) return

    const { layout, text, url, imageUrl } = getValues('lastCard')
    const { lastCardLayout, lastCardText, lastCardUrl, lastCardImage } =
      cardTypeMessage
    if (lastCardLayout !== layout) setValue('lastCard.layout', lastCardLayout)
    if (lastCardText !== text) setValue('lastCard.text', lastCardText)
    if (lastCardUrl !== url) setValue('lastCard.url', lastCardUrl)
    if (lastCardImage && lastCardImage.preview.url !== imageUrl)
      setValue('lastCard.imageUrl', lastCardImage.preview.url)
  }, [cardTypeMessage])

  useEffect(() => {
    if (cardTypeMessageContents === null) return

    if (cardTypeMessageContents.length === 0) {
      appendContent(defaultContentFormValue)
    } else {
      removeContent()
      cardTypeMessageContents.forEach(content => {
        const pickedContent = _.pick(content, [
          'actionButtonText',
          'actionButtonUrl',
          'description',
          'displayActionButton',
          'displayLabel',
          'displayDescription',
          'displayPrice',
          'labelStyle',
          'labelText',
          'title',
        ])
        const contentFormValue: ContentFormValue = {
          ...pickedContent,
          dbid: content.id,
          price: String(content.price),
          image: {
            blob: null,
            url: content.image?.preview.url || null,
            originalIdentifier: content.image?.identifier || null,
          },
        }
        appendContent(contentFormValue)
      })
    }
  }, [cardTypeMessageContents])

  const handleClickSaveButton = () => {
    handleSubmit(submitHandler, submitErrorHandler)()
  }

  const submitHandler = (data: FormValues) => {
    const formData = new FormData()
    const authenticityToken = $('#authenticity_token').val() as string
    formData.append('authenticity_token', authenticityToken)
    // もっと見るカード情報を追加
    const { imageBlob, layout, text, url } = data.lastCard
    formData.append('card_type_message[last_card_layout]', layout)
    if (imageBlob)
      formData.append('card_type_message[last_card_image]', imageBlob)
    if (text) formData.append('card_type_message[last_card_text]', text)
    if (url) formData.append('card_type_message[last_card_url]', url)
    data.removedContentIds.forEach(id => {
      formData.append(
        'card_type_message[removed_card_type_message_content_ids][]',
        String(id)
      )
    })
    // 各カードの情報を追加
    const contentKeys: (keyof ContentFormValue)[] = [
      'actionButtonText',
      'actionButtonUrl',
      'description',
      'displayActionButton',
      'displayDescription',
      'displayLabel',
      'displayPrice',
      'labelStyle',
      'labelText',
      'price',
      'title',
    ]
    data.contents.forEach((d, index) => {
      contentKeys.forEach(key => {
        const snakecaseKey = _.snakeCase(key)
        const value = d[key] as string
        formData.append(
          `card_type_message[card_type_message_contents][${index}][${snakecaseKey}]`,
          value
        )
      })
      if (d.image.blob) {
        formData.append(
          `card_type_message[card_type_message_contents][${index}][image_file]`,
          d.image.blob
        )
      } else if (d.image.originalIdentifier) {
        formData.append(
          `card_type_message[card_type_message_contents][${index}][image_identifier]`,
          d.image.originalIdentifier
        )
      }
      if (d.dbid)
        formData.append(
          `card_type_message[card_type_message_contents][${index}][id]`,
          String(d.dbid)
        )
    })

    dispatch(saveCardTypeMessageAllCards(formData))
      .then(() => {
        UIkit.notification({
          message: "<span uk-icon='icon: check'></span> 設定を保存しました",
          pos: 'top-center',
          status: 'success',
        })
      })
      .catch(() => {
        UIkit.notification({
          message: 'システムエラーで保存ができませんでした',
          pos: 'top-center',
          status: 'danger',
        })
      })
  }

  const submitErrorHandler = (errors: FieldErrors<FormValues>) => {
    const contents = getValues('contents')
    for (let index = 0; index < contents.length; index++) {
      const error = errors.contents?.[index]
      if (error) {
        dispatch(setDisplayContentIndex(index))
        break
      }
    }
    if (errors.lastCard) {
      dispatch(setDisplayContentIndex('last'))
    }
  }
  return (
    <FormProvider {...methods}>
      <div className="uk-margin-bottom">
        <ContentsController
          contentFields={contentFields}
          appendContent={appendContent}
          removeContent={removeContent}
          moveContent={moveContent}
          insertContent={insertContent}
        />
      </div>
      <div className="uk-grid">
        <div className="uk-width-medium">
          <CardPreview />
        </div>
        <div className="uk-width-expand">
          <ContentsList contentFields={contentFields} />
        </div>
      </div>
      <div className="uk-text-center uk-section-small">
        <button
          className="uk-button uk-button-primary uk-button-large uk-width-small"
          onClick={handleClickSaveButton}
        >
          保存
        </button>
      </div>
    </FormProvider>
  )
}

export default ContentsFormRoot
