import React, { useState, useEffect, ChangeEvent } from 'react'
import axios from 'axios'
import ShopCategorySelect from '../../molecules/PushMessage/ShopCategorySelect'
import ShopItemSearchSelect from 'components/molecules/PushMessage/ShopItemSearchSelect'
import MakeshopItemSearchInput from 'components/molecules/PushMessage/MakeshopItemSearchInput'

type Props = {
  company: {
    slug: string
    shopAccountType: 'base' | 'shopify' | 'makeshop' | 'stores'
  }
  type: string
  value: string
  formType: 'order' | 'ship'
  setType: (type: string) => void
  setValue: (value: string) => void
}

const TriggerOrderTypeForm = ({
  company,
  type,
  value,
  formType,
  setType,
  setValue,
}: Props) => {
  const [itemId, setItemId] = useState<string>('')
  const [categoryId, setCategoryId] = useState('')
  const [categoryList, setCategoryList] = useState([])

  useEffect(() => {
    fetchShopCategories()
  }, [])

  useEffect(() => {
    if (value) {
      if (type === 'ordered_item' && itemId !== value) {
        setItemId(value)
      } else if (type === 'ordered_category' && categoryId !== value) {
        setCategoryId(value)
      } else if (type === 'shipped_item' && itemId !== value) {
        setItemId(value)
      } else if (type === 'shipped_category' && categoryId !== value) {
        setCategoryId(value)
      }
    }
  }, [type])

  const fetchShopCategories = () => {
    axios
      .get(`/api/companies/${company.slug}/shop_categories`)
      .then(response => {
        setCategoryList(response.data)
      })
  }

  const changeItemId = (id: string) => {
    setItemId(id)
    setValue(id)
    if (formType === 'order' && type !== 'ordered_item') setType('ordered_item')
    if (formType === 'ship' && type !== 'shipped_item') setType('shipped_item')
  }

  const changeCategoryId = (id: string) => {
    setCategoryId(id)
    setValue(id)
    if (formType === 'order' && type !== 'ordered_category')
      setType('ordered_category')
    if (formType === 'ship' && type !== 'shipped_category')
      setType('shipped_category')
  }

  const handleChangeType = (e: ChangeEvent<HTMLInputElement>) => {
    const newType = e.target.value
    if (newType !== type) {
      setType(newType)
      if (['ordered_item', 'shipped_item'].includes(newType)) {
        setValue(itemId)
      } else if (['ordered_category', 'shipped_category'].includes(newType)) {
        setValue(categoryId)
      } else {
        setValue('')
      }
    }
  }

  const RadioButton = ({ value }: { value: string }) => (
    <input
      type="radio"
      value={value}
      name="trigger-type"
      checked={type === value}
      onChange={handleChangeType}
      className="uk-radio"
      style={{ margin: '0 5px 0 0' }}
    />
  )

  return (
    <div className="uk-section-xsmall">
      <div className="uk-form-label uk-text-bold">
        対象となる商品
        <span className="required-icon">必須</span>
      </div>
      <div className="uk-margin-small">
        <label className="uk-flex uk-flex-middle">
          <RadioButton
            value={formType === 'order' ? 'all_order' : 'order_shipped'}
          />
          <div className="uk-text-small">すべての商品</div>
        </label>
      </div>
      <div className="uk-margin-small">
        <label className="uk-flex uk-flex-middle uk-margin-small-bottom">
          <RadioButton
            value={formType === 'order' ? 'ordered_item' : 'shipped_item'}
          />
          <div className="uk-text-small">商品を指定</div>
        </label>
        {['ordered_item', 'shipped_item'].includes(type) && (
          <div
            className={`uk-margin-left uk-width-1-2@s ${
              itemId ? 'uk-margin-bottom' : ''
            }`}
            style={{ maxWidth: 'calc(100% - 20px)' }}
          >
            {company.shopAccountType === 'makeshop' ? (
              <MakeshopItemSearchInput
                itemId={itemId}
                setItemId={changeItemId}
                company={company}
              />
            ) : (
              <ShopItemSearchSelect
                value={itemId}
                setValue={changeItemId}
                company={company}
              />
            )}
          </div>
        )}
      </div>
      <div className="uk-margin-small">
        <label className="uk-flex uk-flex-middle uk-margin-small-bottom">
          <RadioButton
            value={
              formType === 'order' ? 'ordered_category' : 'shipped_category'
            }
          />
          <div className="uk-text-small">カテゴリーを指定</div>
        </label>
        <div className="uk-margin-left uk-width-2-3 uk-width-1-2@s">
          {['ordered_category', 'shipped_category'].includes(type) && (
            <ShopCategorySelect
              value={categoryId}
              setValue={changeCategoryId}
              categoryList={categoryList}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default TriggerOrderTypeForm
