import React, { useState } from 'react'
import { useModal } from 'react-hooks-use-modal'
import ImageCropEditor from 'components/molecules/ImageCropEditor'
import ImageDropInput from 'components/molecules/ImageDropInput'

interface Props {
  onChange: (blob: Blob) => void
  aspectRatio: number
}

const ContentImageUploadModal = ({ onChange, aspectRatio }: Props) => {
  const [file, setFile] = useState<File | null>()

  const [Modal, openModal, closeModal] = useModal('app-root')

  const handleUploadFile = (file: File) => {
    if (file.size > 10 * 1024 * 1024) {
      window.alert('10MB以下の画像ファイルをアップロードしてください。')
      return
    }

    setFile(file)
  }

  const handleCropImage = (blob: Blob) => {
    onChange(blob)
  }

  const handleCancelCrop = () => {
    setFile(null)
  }

  return (
    <div>
      <button className="uk-button uk-button-primary" onClick={openModal}>
        画像をアップロード
      </button>
      <Modal>
        <div
          className="uk-background-default uk-width-xlarge"
          style={{ maxWidth: '100%' }}
        >
          {file ? (
            <>
              <div className="uk-modal-header">
                <div className="">画像を切り取る</div>
              </div>
              <div className="uk-modal-body">
                <ImageCropEditor
                  width={400}
                  height={400 / aspectRatio}
                  file={file}
                  onCrop={handleCropImage}
                  onCancel={handleCancelCrop}
                />
              </div>
            </>
          ) : (
            <>
              <div className="uk-modal-header">
                <div className="">画像をアップロード</div>
              </div>
              <div className="uk-modal-body">
                <ImageDropInput onChange={handleUploadFile} />
                <div className="uk-text-xsmall uk-text-muted uk-margin-small-top">
                  ファイル形式：JPG、JPEG、PNG
                  <br />
                  ファイルサイズ：10MB以下
                  <br />
                  推奨比率（幅:高さ）：{`${aspectRatio}:1`}
                </div>
              </div>
              <div className="uk-modal-footer uk-text-center">
                <button
                  className="uk-button uk-button-text"
                  onClick={closeModal}
                >
                  キャンセル
                </button>
              </div>
            </>
          )}
        </div>
      </Modal>
    </div>
  )
}

export default ContentImageUploadModal
