import React from 'react'
import { FormValues } from './ContentsFormRoot'
import { useFormContext, useWatch } from 'react-hook-form'
import ContentImageUploadModal from './ContentImageUploadModal'

// TODO: 画像変更
const lastCardLayoutOptions: {
  key: FormValues['lastCard']['layout']
  label: string
  imageUrl: string
}[] = [
  {
    key: 'simple',
    label: 'シンプル',
    imageUrl:
      'https://omiseconnect-assets.s3.ap-northeast-1.amazonaws.com/images/line_account/line_default_icon.jpg',
  },
  {
    key: 'image',
    label: '画像',
    imageUrl:
      'https://omiseconnect-assets.s3.ap-northeast-1.amazonaws.com/images/line_account/line_default_icon.jpg',
  },
]

const LastCardForm = () => {
  const {
    register,
    setValue,
    formState: { errors },
    clearErrors,
  } = useFormContext<FormValues>()

  const [imageUrl, lastCardLayout] = useWatch({
    name: ['lastCard.imageUrl', 'lastCard.layout'],
  })

  const setImageBlob = (blob: Blob) => {
    const imageUrl = window.URL.createObjectURL(blob)
    setValue('lastCard.imageBlob', blob)
    setValue('lastCard.imageUrl', imageUrl)
    clearErrors('lastCard.imageUrl')
  }

  const clearImage = () => {
    if (window.confirm('画像を変更しますか？')) {
      setValue('lastCard.imageBlob', null)
      setValue('lastCard.imageUrl', null)
    }
  }

  return (
    <div>
      <div className="uk-margin-bottom">
        <div className="uk-h4 uk-margin-small-bottom">もっと見るカード</div>
        <div className="uk-text-small uk-text-muted">
          カードタイプメッセージの最後に表示されます。ショップなどのリンクを設定できます。
        </div>
      </div>
      <div className="uk-flex uk-margin-bottom uk-flex-nowrap">
        <div className="uk-width-small uk-flex-none">
          <label
            className="uk-text-small uk-text-secondary"
            style={{ lineHeight: '40px' }}
          >
            レイアウト
          </label>
        </div>
        <div className="uk-flex-1 uk-grid uk-margin-bottom">
          {lastCardLayoutOptions.map(layoutOption => {
            const active = layoutOption.key === lastCardLayout
            return (
              <div key={layoutOption.key}>
                <label
                  className="uk-flex uk-flex-column uk-flex-middle uk-width-small "
                  htmlFor={`last_card_layout_${layoutOption.key}`}
                >
                  <img
                    src={layoutOption.imageUrl}
                    style={active ? { border: '2px solid #00888d' } : {}}
                  />
                  <div className="uk-flex uk-flex-center uk-flex-middle uk-margin-small-top">
                    <input
                      className="uk-radio uk-margin-xsmall-right"
                      type="radio"
                      value={layoutOption.key}
                      id={`last_card_layout_${layoutOption.key}`}
                      {...register('lastCard.layout')}
                    />
                    {layoutOption.label}
                  </div>
                </label>
              </div>
            )
          })}
        </div>
      </div>
      {lastCardLayout === 'image' && (
        <div className="uk-flex uk-margin-bottom uk-flex-nowrap">
          <div className="uk-width-small uk-flex-none">
            <label
              className="uk-text-small uk-text-secondary"
              style={{ lineHeight: '40px' }}
            >
              画像
            </label>
          </div>
          <div className="uk-flex-1">
            {imageUrl ? (
              <div className="uk-inline">
                <img src={imageUrl} width={200} />
                <div
                  className="uk-position-top-right uk-overlay-default uk-overlay-primary uk-border-rounded"
                  style={{
                    padding: '1px 4px 3px',
                    margin: 5,
                    cursor: 'pointer',
                  }}
                  onClick={clearImage}
                >
                  <span uk-icon="close" />
                </div>
              </div>
            ) : (
              <ContentImageUploadModal
                aspectRatio={3 / 2}
                onChange={setImageBlob}
              />
            )}
            {errors.lastCard?.imageUrl && (
              <div className="uk-text-danger uk-text-small uk-margin-xsmall-top">
                {errors.lastCard.imageUrl.message}
              </div>
            )}
            <input
              type="hidden"
              {...register('lastCard.imageUrl', {
                required: '画像が登録されていません。',
              })}
            />
          </div>
        </div>
      )}
      <div className="uk-flex uk-margin-bottom uk-flex-nowrap">
        <div className="uk-width-small uk-flex-none">
          <label
            className="uk-text-small uk-text-secondary"
            style={{ lineHeight: '40px' }}
          >
            テキスト
          </label>
        </div>
        <div className="uk-flex-1">
          <input
            type="text"
            className={`uk-input ${
              errors.lastCard?.text ? 'uk-form-danger' : ''
            }`}
            placeholder="テキストを入力"
            {...register('lastCard.text', {
              required: 'テキストを入力してください。',
              maxLength: {
                value: 20,
                message: '20文字以下で入力してください。',
              },
            })}
          />
          {errors.lastCard?.text && (
            <div className="uk-text-danger uk-text-small uk-margin-xsmall-top">
              {errors.lastCard.text.message}
            </div>
          )}
        </div>
      </div>
      <div className="uk-flex uk-margin-bottom uk-flex-nowrap">
        <div className="uk-width-small uk-flex-none">
          <label
            className="uk-text-small uk-text-secondary"
            style={{ lineHeight: '40px' }}
          >
            URL
          </label>
        </div>
        <div className="uk-flex-1">
          <input
            type="text"
            className={`uk-input ${
              errors.lastCard?.url ? 'uk-form-danger' : ''
            }`}
            placeholder="URLを入力"
            {...register('lastCard.url', {
              required: 'URLを入力してください。',
              maxLength: {
                value: 200,
                message: '200文字以下で入力してください。',
              },
              pattern: {
                value: /^https?:\/\/[-_.!~*()a-zA-Z0-9;?:&=+,%#]+/,
                message: 'URLの形式が不正です。',
              },
            })}
          />
          {errors.lastCard?.url && (
            <div className="uk-text-danger uk-text-small uk-margin-xsmall-top">
              {errors.lastCard.url.message}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default LastCardForm
