import React, { useState, useEffect } from 'react'
import axios from 'axios'
import _ from 'lodash'
import moment from 'moment'
import styled from 'styled-components'
import { useModal } from 'react-hooks-use-modal'
import ReactLoading from 'react-loading'
import MessagePreview from '../organisms/PushMessage/MessagePreview'
import MessageObjectsEditor from '../organisms/PushMessage/MessageObjectsEditor'
import { includeOcDomainUri } from '../../lib/MessageObjectUtil'
import UserCustomerConnectForm from '../organisms/UserCustomerConnectForm'

const defaultScheduledDatetime = moment()
  .format()
  .replace(/:\d+\+.*/, '')

const PushMessageReservationForm = ({
  company,
  filterJson,
  filterText,
  reservation,
  scheduled,
}) => {
  const [reservationType, setReservationType] = useState('realtime')
  const [scheduledDatetime, setScheduledDatetime] = useState(
    defaultScheduledDatetime
  )
  const [messageObjects, setMessageObjects] = useState([])
  const [isSending, setIsSending] = useState(false)
  const [isSaved, setIsSaved] = useState(false)
  const [deletedMessageIds, setDeletedMessageIds] = useState({
    text: [],
    image: [],
  })
  const [saveAsDraft, setSaveAsDraft] = useState(false)
  const [reservationId, setReservationId] = useState(null)
  const [isSendingTestMessage, setIsSendingTestMessage] = useState(false)
  const [isTestMessageSent, setIsTestMessageSent] = useState(false)

  const [Modal, openModal, closeModal, isModalOpen] = useModal('form-root', {
    preventScroll: true,
    closeOnOverlayClick: false,
  })

  const [SendingTestModal, openSendingTestModal, closeSendingTestModal] =
    useModal('form-root', {
      closeOnOverlayClick: false,
    })

  useEffect(() => {
    const defaultObjects = [getDefaultMessageObject()]
    setMessageObjects(defaultObjects)

    if (reservation) {
      setReservationId(reservation.id)
      if (reservation.scheduledDatetime) {
        setReservationType('schedule')
        setScheduledDatetime(
          moment(reservation.scheduledDatetime)
            .format()
            .replace(/:\d+\+.*/, '')
        )
      } else {
        setReservationType('realtime')
      }
      if (reservation.pushMessageObjects.length > 0) {
        resetMessageObjects(reservation.pushMessageObjects)
      }
    }
  }, [])

  useEffect(() => {
    if (!isModalOpen) {
      setIsSaved(false)
      setSaveAsDraft(false)
    }
  }, [isModalOpen])

  const availableKeywords = ['AccountName', 'ShopUrl']
  if (company.companyLineAccount.allFriendsSaved)
    availableKeywords.push('Nickname')

  const resetMessageObjects = messageObjects => {
    setMessageObjects(
      messageObjects.map(obj => {
        if (obj.type === 'image' && obj.uri) obj.type = 'imageWithLink'
        obj.identifier = `${obj.type}-${obj.id}`
        return obj
      })
    )
  }

  const resetDeletedMessageIds = () => {
    setDeletedMessageIds({ text: [], image: [] })
  }

  const orderedMessageObjects = _.sortBy(messageObjects, 'order')

  const getDefaultMessageObject = () => ({
    type: null,
    order:
      messageObjects.length > 0
        ? (orderedMessageObjects.slice(-1)[0].order || 0) + 1
        : 0,
    identifier: (Math.random() + 1).toString(36).substring(7),
  })

  const addDefaultMessageObject = () => {
    if (messageObjects.length >= 5) return

    setMessageObjects([messageObjects, getDefaultMessageObject()].flat())
  }

  const deletePersistedObject = deletedObject => {
    if (!deletedObject.id) return

    const newIds = Object.assign({}, deletedMessageIds)
    if (deletedObject.type === 'text') {
      newIds.text.push(deletedObject.id)
    } else {
      newIds.image.push(deletedObject.id)
    }
    setDeletedMessageIds(newIds)
  }

  const isValid = obj => {
    if (obj.type === 'text') {
      return obj.text !== ''
    }
    const isImageValid = !_.isNil(obj.file) || obj.imageSrc
    if (obj.type === 'image') {
      return isImageValid
    }
    if (obj.type === 'imageWithLink') {
      return (
        isImageValid && obj.uri && !(obj.altText && obj.altText.length > 100)
      )
    }
    return false
  }

  const validMessageObjects = orderedMessageObjects.filter(obj => isValid(obj))

  const messageUriValid = !includeOcDomainUri(orderedMessageObjects)

  const messageValid = validMessageObjects.length !== 0 && messageUriValid

  const handleChangeMessageObjects = newObjects => {
    if (newObjects.length === 0) {
      setMessageObjects([getDefaultMessageObject()])
    } else {
      setMessageObjects(newObjects)
    }
  }

  const handleDeleteSubmit = () => {
    const url = `/api/companies/${company.slug}/push_message_reservations/${reservationId}`
    const data = { authenticity_token: $('#authenticity_token').val() }

    if (window.confirm('メッセージ配信を削除してよろしいですか？')) {
      axios
        .delete(url, { data })
        .then(
          _ =>
            (window.location.href = `/flash_messages/redirect_with_flash_message?message=メッセージ配信を削除しました&type=notice&url=/companies/${company.slug}/push_message_reservations${targetFilteringParams}`)
        )
        .catch(() => {
          window.alert('メッセージ配信を削除できませんでした')
        })
    }
  }

  const handleClickSaveAsDraft = () => {
    setSaveAsDraft(true)
    openModal()
    handleSubmit({ saveAsDraft: true })
  }

  const handleSubmit = ({ saveAsDraft, callback }) => {
    if (isSending) return

    if (!messageValid) return

    setIsSending(true)
    const formData = new FormData()
    formData.append('authenticity_token', $('#authenticity_token').val())
    formData.append('reservation_type', reservationType)
    formData.append('save_as_draft', saveAsDraft || false)
    formData.append('deleted_message_object_ids[text]', deletedMessageIds.text)
    formData.append(
      'deleted_message_object_ids[image]',
      deletedMessageIds.image
    )
    formData.append(
      'push_message_reservation[scheduled_datetime]',
      scheduledDatetime
    )
    if (filterJson)
      formData.append('push_message_reservation[filter_json]', filterJson)
    const textMessageObjects = _.filter(
      validMessageObjects,
      obj => obj.type === 'text'
    )
    textMessageObjects.forEach((obj, index) => {
      const parent =
        'push_message_reservation[push_message_text_objects_attributes]'
      formData.append(`${parent}[${index}][text]`, obj.text)
      formData.append(`${parent}[${index}][order]`, obj.order)
      if (obj.id) formData.append(`${parent}[${index}][id]`, obj.id)
    })
    const imageMessageObjects = _.filter(
      validMessageObjects,
      obj => obj.type === 'image' || obj.type === 'imageWithLink'
    )
    imageMessageObjects.forEach((obj, index) => {
      const parent =
        'push_message_reservation[push_message_image_objects_attributes]'
      if (obj.file) {
        formData.append(
          `${parent}[${index}][image]`,
          obj.file,
          `${new Date().getTime()}-${(Math.random() + 1)
            .toString(36)
            .substring(7)}.${obj.file.name.split('.').pop()}`
        )
      }
      formData.append(`${parent}[${index}][uri]`, obj.uri || '')
      formData.append(`${parent}[${index}][alt_text]`, obj.altText || '')
      formData.append(`${parent}[${index}][order]`, obj.order)
      if (obj.id) formData.append(`${parent}[${index}][id]`, obj.id)
    })

    if (reservationId) {
      const url = `/api/companies/${company.slug}/push_message_reservations/${reservationId}`
      axios
        .put(url, formData)
        .then(response => {
          const { pushMessageObjects } = response.data
          resetMessageObjects(pushMessageObjects)
          resetDeletedMessageIds()
          setIsSaved(true)
          setIsSending(false)
          if (callback) callback(reservationId)
        })
        .catch(error => {
          const { message } = error.response.data
          window.alert(message)
          setIsSending(false)
          closeModal()
        })
    } else {
      const url = `/api/companies/${company.slug}/push_message_reservations`
      axios
        .post(url, formData)
        .then(response => {
          const { id, pushMessageObjects } = response.data
          setReservationId(id)
          history.replaceState(
            '',
            '',
            `/companies/${company.slug}/push_message_reservations/${id}/edit`
          )
          resetMessageObjects(pushMessageObjects)
          setIsSaved(true)
          setIsSending(false)
          window.dataLayer.push({ event: 'user_reservation_msg_registered' })
          if (callback) callback(id)
        })
        .catch(error => {
          const { message } = error.response.data
          window.alert(message)
          setIsSending(false)
          closeModal()
        })
    }
  }

  const saveAndsendTestMessage = () => {
    setIsSendingTestMessage(true)
    setSaveAsDraft(true)
    handleSubmit({
      saveAsDraft: true,
      callback: id => {
        const data = { authenticity_token: $('#authenticity_token').val() }
        axios
          .post(
            `/api/companies/${company.slug}/push_message_reservations/${id}/send_test_message`,
            data
          )
          .then(_res => {
            setIsTestMessageSent(true)
            setIsSendingTestMessage(false)
            window.dataLayer.push({ event: 'user_test_reservation_msg_sent' })
          })
      },
    })
  }

  const handleClickCloseSendingTestModal = () => {
    closeSendingTestModal()
    setIsSaved(false)
    setIsTestMessageSent(false)
  }

  const targetFilteringParams = `?target_filtering=${
    filterJson ? 'true' : 'false'
  }`

  const ConfirmModal = (
    <Modal>
      <ModalInner style={{ maxWidth: window.innerWidth - 30 }}>
        {(() => {
          if (isSaved) {
            return saveAsDraft ? (
              <div className="uk-text-center">
                <h4>下書き保存しました！</h4>
                <div className="uk-flex uk-flex-column uk-flex-middle">
                  <div
                    className="uk-button uk-button-primary"
                    onClick={() => {
                      closeModal()
                      setIsSaved(false)
                    }}
                  >
                    入力へ戻る
                  </div>
                  <a
                    className="uk-button uk-button-default uk-margin-top"
                    href={`/companies/${company.slug}/push_message_reservations${targetFilteringParams}`}
                  >
                    保存済みの配信一覧へ
                  </a>
                </div>
              </div>
            ) : (
              <div className="uk-text-center">
                <h4>登録が完了しました！</h4>
                <p>{`${
                  reservationType === 'realtime'
                    ? 'この後すぐに'
                    : '登録した日時になりましたら自動で'
                }メッセージが配信されます。`}</p>
                <a
                  className="uk-button uk-button-primary"
                  href={`/companies/${company.slug}/push_message_reservations${targetFilteringParams}`}
                >
                  配信メッセージ一覧へ
                </a>
              </div>
            )
          } else if (isSending) {
            return (
              <>
                <h4 className="uk-text-center">
                  メッセージ内容を登録中です...
                </h4>
                <div className="uk-section">
                  <ReactLoading
                    className="uk-margin-auto"
                    type={'spin'}
                    color={'#00888d'}
                    height={100}
                    width={100}
                  />
                </div>
              </>
            )
          } else {
            return (
              <>
                <h4 className="uk-text-center">
                  {`${
                    reservationType === 'realtime' ? '配信' : '登録完了'
                  }まであと少しです！`}
                </h4>
                <div className="uk-text-center uk-margin">
                  <img
                    className="uk-width-1-2 uk-width-1-3@s"
                    src="https://omiseconnect-assets.s3.ap-northeast-1.amazonaws.com/images/dashboard/push_message/sending.png"
                  />
                </div>
                <div className="uk-text-small">{`下記の内容でメッセージを${
                  reservationType === 'realtime' ? '配信' : '登録'
                }してよろしいですか？`}</div>
                <ConfirmModalTable className="uk-text-small uk-margin-small-top uk-margin-bottom">
                  <ConfirmModalTableContent className="uk-width-1-4 uk-text-center">
                    送信日時
                  </ConfirmModalTableContent>
                  <ConfirmModalTableContent className="uk-width-3-4">
                    {reservationType === 'realtime'
                      ? '今すぐ'
                      : moment(scheduledDatetime).format(
                          'YYYY年MM月DD日 HH:mm'
                        )}
                  </ConfirmModalTableContent>
                  <ConfirmModalTableContent className="uk-width-1-4 uk-text-center">
                    送信先
                  </ConfirmModalTableContent>
                  <ConfirmModalTableContent className="uk-width-3-4">
                    {filterText || 'すべてのLINE友だち'}
                  </ConfirmModalTableContent>
                </ConfirmModalTable>
                <div className="uk-grid uk-grid-small uk-child-width-1-4@s uk-child-width-1-2 uk-flex-center">
                  <div>
                    <button
                      className="uk-button uk-button-default uk-modal-close uk-width-1-1"
                      type="button"
                      onClick={closeModal}
                    >
                      入力へ戻る
                    </button>
                  </div>
                  <div>
                    <div
                      className="uk-button uk-button-primary uk-width-1-1"
                      onClick={handleSubmit}
                    >
                      {reservationType === 'realtime' ? '配信する' : '登録する'}
                    </div>
                  </div>
                </div>
              </>
            )
          }
        })()}
      </ModalInner>
    </Modal>
  )

  const { freeMessagesLimit, remainingMessagesCount } =
    company.companyLineAccount
  const targetNum = reservation.targetCustomersCount
  const displayMessageRemainingAlert =
    !_.isNil(remainingMessagesCount) &&
    (remainingMessagesCount === 0 || targetNum / remainingMessagesCount >= 0.98)

  return (
    <div id="form-root">
      <div className="uk-section-xsmall">
        <div className="uk-form-label uk-text-bold uk-margin-bottom">
          配信対象
        </div>
        <div className="uk-background-muted uk-padding-small uk-text-small">
          {filterText || 'すべてのLINE友だち'}
        </div>
        {!(company.isSelfLine && targetNum === 0) && (
          <div
            className="uk-text-small uk-margin-top"
            style={{ lineHeight: 1.8 }}
          >
            対象人数：
            <b>{targetNum.toLocaleString()}</b>人
            <span className="uk-text-xsmall uk-text-muted">
              （※
              表示人数は現時点での推計値のため、実際の人数とは異なる場合がございます）
            </span>
            {!_.isNil(freeMessagesLimit) && !_.isNil(remainingMessagesCount) && (
              <>
                <br />
                今月の残りメッセージ数（概算）：{' '}
                <b>{remainingMessagesCount.toLocaleString()}</b>
                {` / ${freeMessagesLimit.toLocaleString()}通`}
              </>
            )}
          </div>
        )}
        {displayMessageRemainingAlert &&
          (() => {
            if (company.currentPlan === 'free') {
              return (
                <div className="uk-section uk-padding-small uk-alert-danger uk-text-small uk-margin-top">
                  今月のメッセージ送信可能数が足りない可能性がございます。
                  <br />
                  <span className="uk-text-xsmall">
                    ※
                    メッセージ送信可能数が足りなかった場合は、自動的にメッセージ配信がキャンセルされます。
                  </span>
                </div>
              )
            } else if (remainingMessagesCount === 0) {
              return (
                <div className="uk-section uk-padding-small uk-alert-warning uk-text-small uk-margin-top">
                  すでに今月の無料メッセージを全て消費しているため、送信数に応じて料金が発生します。
                </div>
              )
            } else {
              return (
                <div className="uk-section uk-padding-small uk-alert-danger uk-text-small uk-margin-top">
                  メッセージ送信数が残り無料メッセージ数を超える可能性がございます。
                  <br />
                  <span className="uk-text-xsmall">
                    ※
                    無料枠が足りなかった場合は、足りない分のみ自動的に有料メッセージとして配信されます。
                  </span>
                </div>
              )
            }
          })()}
      </div>
      <div className="uk-section-xsmall">
        <div className="uk-form-label uk-text-bold uk-margin-bottom">
          配信スケジュール
          <span className="required-icon">必須</span>
        </div>
        <label className="uk-flex uk-flex-middle uk-margin-small">
          <input
            type="radio"
            value="realtime"
            name="reservation-type"
            checked={reservationType === 'realtime'}
            onChange={e => setReservationType(e.target.value)}
            className="uk-radio uk-margin-small-right"
          />
          <div className="uk-text-small"> 今すぐ配信する</div>
        </label>
        <div className="uk-flex uk-flex-middle uk-margin-small">
          <input
            type="radio"
            value="schedule"
            name="reservation-type"
            checked={reservationType === 'schedule'}
            onChange={e => setReservationType(e.target.value)}
            className="uk-radio uk-margin-small-right"
          />
          <input
            className="uk-input uk-form-small uk-width-2-3 uk-width-1-3@s"
            value={scheduledDatetime}
            min={defaultScheduledDatetime}
            onChange={e => setScheduledDatetime(e.currentTarget.value)}
            onFocus={() => setReservationType('schedule')}
            type="datetime-local"
          />
        </div>
      </div>
      <div className="uk-section-xsmall message-editor-width">
        <div className="uk-form-label uk-text-bold uk-margin-small-bottom">
          メッセージ内容
        </div>
        <div className="uk-text-muted uk-text-xsmall uk-margin-bottom">
          複数の吹き出しを登録した場合でも、送信数は1人あたり1通として計算されます。
        </div>
        <MessageObjectsEditor
          messageObjects={orderedMessageObjects}
          onChange={handleChangeMessageObjects}
          deletePersistedObject={deletePersistedObject}
          addMessageObject={addDefaultMessageObject}
          company={company}
          availableKeywords={availableKeywords}
        />
      </div>
      <div className="uk-section-xsmall message-editor-width">
        <div className="uk-flex uk-flex-column uk-flex-middle">
          {!messageUriValid && (
            <div className="uk-text-danger uk-text-center uk-margin-bottom uk-text-small">
              <b>「omiseconnect.jp」</b>
              が含まれるURLを指定することはできません。
            </div>
          )}
          <div className="uk-flex uk-flex-column">
            <button
              className="uk-button uk-button-large uk-button-primary uk-margin-bottom"
              onClick={() => {
                setSaveAsDraft(false)
                openModal()
              }}
              disabled={!messageValid}
            >
              {reservationType === 'realtime'
                ? 'メッセージを配信'
                : '配信予約を登録'}
            </button>
            <button
              className="uk-button uk-button-default uk-margin-bottom"
              onClick={handleClickSaveAsDraft}
              disabled={!messageValid}
            >
              {scheduled ? '下書きに戻す' : '下書きとして保存'}
            </button>
            <button
              className="uk-button uk-button-default uk-margin-bottom"
              onClick={openSendingTestModal}
              disabled={!messageValid}
            >
              テスト配信
            </button>
            {reservationId && (
              <button
                className="uk-button uk-button-danger"
                onClick={handleDeleteSubmit}
              >
                削除する
              </button>
            )}
          </div>
        </div>
      </div>
      <MessagePreview messageObjects={validMessageObjects} company={company} />
      {ConfirmModal}
      <SendingTestModal>
        <ModalInner style={{ maxWidth: window.innerWidth - 30 }}>
          <div className="uk-h4">テスト配信</div>
          {isTestMessageSent ? (
            <>
              <div className="uk-margin-bottom">
                メッセージの送信準備が完了しました。
                <br />
                まもなくテスト配信が行われますので、LINEアカウントにてご確認ください。
              </div>
              <div className="uk-text-center">
                <div
                  className="uk-button uk-button-text uk-padding-remove"
                  onClick={handleClickCloseSendingTestModal}
                >
                  閉じる
                </div>
              </div>
            </>
          ) : isSending || isSendingTestMessage ? (
            <>
              <div className="uk-text-center">テスト配信の準備中です...</div>
              <div className="uk-section">
                <ReactLoading
                  className="uk-margin-auto"
                  type={'spin'}
                  color={'#00888d'}
                  height={100}
                  width={100}
                />
              </div>
            </>
          ) : (
            <>
              <div className="uk-text-small">
                実際にお客さまにメッセージを配信する前に、自分のLINEアカウントにメッセージをテスト配信することが可能です。
              </div>
              <div className="uk-h5">テスト配信用LINEアカウント</div>
              <UserCustomerConnectForm
                company={company}
                sendMessage={() => saveAndsendTestMessage()}
              />
              <div className="uk-text-center uk-margin-top">
                <div
                  className="uk-button uk-button-text uk-padding-remove"
                  onClick={handleClickCloseSendingTestModal}
                >
                  キャンセル
                </div>
              </div>
            </>
          )}
        </ModalInner>
      </SendingTestModal>
    </div>
  )
}
export default PushMessageReservationForm

const ModalInner = styled.div`
  background-color: #fff;
  padding: 30px 25px;
  width: 600px;
  box-sizing: border-box;
`
const ConfirmModalTable = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid;
  border-left: 1px solid;
`
const ConfirmModalTableContent = styled.div`
  border-bottom: 1px solid;
  border-right: 1px solid;
  padding: 8px;
`
