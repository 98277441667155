import React from 'react'
import { useAppSelector } from '../../hooks'
import { useWatch } from 'react-hook-form'
import { RootState } from 'components/Dashboard/stores'
import { FormValues } from './ContentsFormRoot'
import { ShopItemDefaultImage } from 'components/Dashboard/const'

const CardPreview = () => {
  const {
    cardTypeMessage,
    displayContentIndex,
    cardTypeMessageContentLabelStyles,
  } = useAppSelector((state: RootState) => state.cardTypeMessage)
  const contents: FormValues['contents'] = useWatch({ name: 'contents' }) || []
  const lastCard: FormValues['lastCard'] = useWatch({ name: 'lastCard' })

  // TODO: デザイン実装（デザイン実装に伴い以下大きく変更する可能性あり）
  if (displayContentIndex === 'last') {
    const text = lastCard.text || 'テキストを入力'
    const imageUrl = lastCard.imageUrl || ShopItemDefaultImage
    return (
      <div className="uk-card uk-card-default">
        {lastCard.layout === 'simple' && (
          <div className="uk-card-body">
            <div className="uk-text-center">{text}</div>
          </div>
        )}
        {lastCard.layout === 'image' && (
          <div>
            <div className="uk-card-media-top">
              <img src={imageUrl} />
            </div>
            <div className="uk-card-body">
              <div className="uk-text-center">{text}</div>
            </div>
          </div>
        )}
      </div>
    )
  } else {
    const currentContent = contents[displayContentIndex]
    if (!cardTypeMessage || !currentContent) return <div />

    const labelText = currentContent.labelText || 'タグを入力'
    const selectedLabelStyle = cardTypeMessageContentLabelStyles.find(
      s => s.number === currentContent.labelStyle
    )
    const labelTextColor = selectedLabelStyle?.text || '#42659a'
    const labelBackgroundColor = selectedLabelStyle?.background || '#ffffff'
    const imageUrl = currentContent.image.url || ShopItemDefaultImage
    const title = currentContent.title || 'タイトルを入力'
    const description = currentContent.description || '説明文を入力'
    const priceText = Number(currentContent.price || '1234').toLocaleString()
    const buttonText = currentContent.actionButtonText || 'ボタンのラベルを入力'
    const {
      displayActionButton,
      displayDescription,
      displayLabel,
      displayPrice,
    } = currentContent

    return (
      <div>
        {cardTypeMessage.layout === 'product' && (
          <div className="uk-card uk-card-default">
            <div className="uk-card-media-top">
              <img src={imageUrl} />
              {displayLabel && (
                <div
                  className="uk-overlay uk-position-top-left uk-padding-small uk-margin-small-top uk-margin-small-left"
                  style={{
                    color: labelTextColor,
                    backgroundColor: labelBackgroundColor,
                  }}
                >
                  {labelText}
                </div>
              )}
            </div>
            <div className="uk-card-body">
              <div className="uk-text-secondary">{title}</div>
              {displayDescription && (
                <div className="uk-text-small">{description}</div>
              )}
              {displayPrice && (
                <div className="uk-text-right">{priceText}円</div>
              )}
              {displayActionButton && (
                <div className="uk-text-center uk-margin-top">{buttonText}</div>
              )}
            </div>
          </div>
        )}
        {cardTypeMessage.layout === 'image' && (
          <div className="uk-card uk-card-default">
            <div className="uk-card-media-top">
              <img src={imageUrl} />
              {displayLabel && (
                <div
                  className="uk-overlay uk-position-top-left uk-padding-small uk-margin-small-top uk-margin-small-left"
                  style={{
                    color: labelTextColor,
                    backgroundColor: labelBackgroundColor,
                  }}
                >
                  {labelText}
                </div>
              )}
              {displayActionButton && (
                <div className="uk-overlay uk-position-bottom-center">
                  {buttonText}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default CardPreview
