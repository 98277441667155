import { combineReducers } from 'redux'
import { configureStore } from '@reduxjs/toolkit'

import customerShopLogsReducer from './customerShopLogs'
import customersReducer from './customers'
import companyReducer from './company'
import chatReducer from './chat'
import layoutReducer from './layout'

const reducer = combineReducers({
  customerShopLogs: customerShopLogsReducer,
  chat: chatReducer,
  company: companyReducer,
  customers: customersReducer,
  layout: layoutReducer,
})

const store = configureStore({ reducer })

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
