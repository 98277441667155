import React, { useState, useEffect, ChangeEvent } from 'react'
import styled from 'styled-components'
import { EmojiData, BaseEmoji } from 'emoji-mart'
import EmojiPicker from '../../../TalkRoom/components/EmojiPicker'
import { Company, RichmenuAction } from '../../types'
import { runes } from 'runes2'

interface Props {
  company: Company
  actions: RichmenuAction[]
  index: number
  setActions: (actions: RichmenuAction[]) => void
  templateId: string
}

const ActionSelect = ({
  company,
  actions,
  index,
  setActions,
  templateId,
}: Props) => {
  const [textareaValue, setTextareaValue] = useState('')
  const [actionKey, setActionKey] = useState('')
  const [selectValue, setSelectValue] = useState('')

  useEffect(() => {
    if (actions[index]) {
      if (actions[index][`action_${index}`]) {
        if (Object.keys(actions[index][`action_${index}`])[0] === 'data') {
          if (
            actions[index][`action_${index}`]['data'].includes(
              'message_response'
            )
          ) {
            setActionKey('message_response')
          } else
            return setActionKey(
              Object.keys(actions[index][`action_${index}`])[0]
            )
        } else
          return setActionKey(Object.keys(actions[index][`action_${index}`])[0])
      }
    }
  })

  useEffect(() => {
    if (actionKey === 'uri') setSelectValue('uri')
    if (actionKey === 'data')
      setSelectValue(actions[index][`action_${index}`]['data'])
    if (actionKey === 'message_response') {
      setSelectValue('message_response')
      const str = actions[index][`action_${index}`]['data']
      if (str) setTextareaValue(str.split('message_response&text=')[1])
    }
  }, [actionKey])

  useEffect(() => {
    setActionKey('')
    setSelectValue('')
  }, [templateId])

  const actionOptions = () => {
    const options = [
      { text: '選択してください', value: '' },
      { text: '商品を見る', value: 'reply_items' },
      { text: 'メッセージ応答 ', value: 'message_response' },
      { text: 'リンク', value: 'uri' },
      { text: '何もしない', value: 'not_set' },
    ]
    if (!company.isSelfLine)
      options.splice(1, 0, {
        text: 'お問い合わせ',
        value: 'reply_inquiry_response',
      })

    return options
  }

  const handleSelectEmoji = (emoji: EmojiData) => {
    if (Object.keys(emoji).includes('native')) {
      const { native } = emoji as BaseEmoji
      if (native) {
        setTextareaValue(textareaValue + native)
      }
    }
  }

  const handleActionChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setActions(
      actions.map((action: RichmenuAction, i: number) => {
        if (i === index) {
          if (e.target.value === 'message_response')
            return {
              ...action,
              [`action_${index}`]: { message_response: '' },
            }
          else if (e.target.value === 'uri')
            return { ...action, [`action_${index}`]: { uri: '' } }
          else
            return { ...action, [`action_${index}`]: { data: e.target.value } }
        }
        return action
      })
    )
  }

  return (
    <>
      <select
        className="uk-select uk-width-medium"
        name={`rich_menu[area_actions][action_${index}][${actionKey}]`}
        value={selectValue}
        onChange={e => handleActionChange(e)}
      >
        {actionOptions().map(option => (
          <option key={option['value']} value={option['value']}>
            {option['text']}
          </option>
        ))}
      </select>
      {actionKey === 'uri' && (
        <input
          type="text"
          name={`rich_menu[area_actions][action_${index}][uri]`}
          className="uk-margin-small-top uk-input uk-form-small uk-width-medium"
          placeholder="URLを入力"
          defaultValue={actions[index][`action_${index}`]['uri']}
        />
      )}
      {actionKey === 'message_response' && (
        <div>
          <EditorMain>
            <textarea
              value={textareaValue}
              onChange={e => setTextareaValue(e.target.value)}
              className="uk-textarea uk-margin-small-top uk-width-medium"
              rows={6}
              name={`rich_menu[area_actions][action_${index}][message_response]`}
              style={{ resize: 'none' }}
            />
            <EmojiPickerWrapper>
              <EmojiPicker
                onSelect={handleSelectEmoji}
                iconStyle={{
                  fontSize: 25,
                }}
                modalStyle={{
                  bottom: 25,
                  right: 0,
                }}
              />
            </EmojiPickerWrapper>
          </EditorMain>
          <div
            className="uk-text-small uk-text-muted uk-flex-none uk-margin-small-left uk-margin-small-top"
            style={{ width: 76 }}
          >
            <span
              className={`uk-text-bold ${
                runes(textareaValue).length > 250 ? 'uk-text-danger' : ''
              }`}
            >
              {runes(textareaValue).length}
            </span>
            /250文字
          </div>
        </div>
      )}
    </>
  )
}

export default ActionSelect
const EditorMain = styled.div`
  position: relative;
`

const EmojiPickerWrapper = styled.div`
  position: absolute;
  bottom: 5px;
  right: 9px;
`
