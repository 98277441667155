import React from 'react'
import styled from 'styled-components'
// https://github.com/sindresorhus/file-type/tree/3b08ab1e7404dc00dfb3050d6f34821ae300b59c#browser
import FileType from 'file-type/browser'
import CancelIcon from '@mui/icons-material/Cancel'
import _ from 'lodash'
import MessageTextObjectInput from '../../molecules/PushMessage/MessageTextObjectInput'
import ImageDropInput from '../../molecules/ImageDropInput'

const messageObjectTypes = [
  { key: 'text', label: 'テキスト' },
  { key: 'image', label: '画像' },
  { key: 'imageWithLink', label: 'リンク付き画像' },
]

const imageSrc = file =>
  ((window.URL || window.webkitURL).createObjectURL || window.createObjectURL)(
    file
  )

const MessageObjectFormCard = ({
  messageObject,
  index,
  onChange,
  remove,
  changeOrder,
  company,
  upIconActive,
  downIconActive,
  availableKeywords,
}) => {
  const isEdited =
    (messageObject.type === 'text' &&
      !_.isNil(messageObject.text) &&
      messageObject.text !== '') ||
    ((messageObject.type === 'image' ||
      messageObject.type === 'imageWithLink') &&
      (!_.isNil(messageObject.file) || messageObject.imageSrc)) ||
    (messageObject.type === 'imageWithLink' &&
      !_.isNil(messageObject.uri) &&
      messageObject.uri !== '')

  const selectMessageType = key => {
    messageObject.type = key
    if (key === 'text') {
      messageObject.text = ''
    } else if (key === 'imageWithLink') {
      messageObject.uri = ''
    }
    onChange(messageObject)
  }

  const changeObjectFile = async file => {
    if (file) {
      if (file.size > 10_000_000) {
        window.alert(
          '画像のサイズが大きすぎるため登録できません（最大サイズは10MBです）'
        )
        return
      }
      if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
        window.alert(
          '画像の登録ができませんでした。登録できる画像は「.jpg」または「.png」形式の画像のみです。'
        )
        return
      }
      const fileType = await FileType.fromBlob(file)
      if (!['image/png', 'image/jpeg'].includes(fileType?.mime)) {
        window.alert(
          '画像ファイルに問題があるため登録できませんでした。画像を変更してお試しください。'
        )
        return
      }
    }
    messageObject.file = file
    messageObject.imageSrc = file ? imageSrc(file) : null
    onChange(messageObject)
  }

  const resetMessageType = () => {
    if (messageObject.id) return

    if (
      isEdited &&
      !window.confirm('入力中の吹き出し内容は破棄されますが、よろしいですか？')
    )
      return

    onChange({
      type: null,
      order: messageObject.order,
      identifier: messageObject.identifier,
    })
  }

  return (
    <div className="uk-card uk-card-default uk-margin-bottom">
      <div className="uk-card-header uk-padding-small uk-flex uk-flex-between uk-flex-middle">
        <div className="uk-text-small">
          {`${index + 1}件目`}
          {index === 0 && <span className="required-icon">必須</span>}
        </div>
        <div>
          <MessageObjectHeaderIcon style={{ opacity: upIconActive ? 1 : 0.5 }}>
            <span
              uk-icon="chevron-up"
              ratio="1"
              onClick={() => upIconActive && changeOrder('up')}
            />
          </MessageObjectHeaderIcon>
          <MessageObjectHeaderIcon
            style={{ opacity: downIconActive ? 1 : 0.5 }}
          >
            <span
              uk-icon="chevron-down"
              ratio="1"
              onClick={() => downIconActive && changeOrder('down')}
            />
          </MessageObjectHeaderIcon>
          <MessageObjectHeaderIcon>
            <span uk-icon="close" ratio=".9" onClick={remove} />
          </MessageObjectHeaderIcon>
        </div>
      </div>
      <div className="uk-card-body uk-padding-small">
        {messageObject.type === null && (
          <div className="uk-text-center uk-padding-small">
            <div className="uk-margin-bottom">
              吹き出しの種類を選んでください
            </div>
            <div className="uk-grid uk-grid-small uk-child-width-1-3@s uk-child-width-1-1">
              {messageObjectTypes.map(type => (
                <div key={type.key}>
                  <MessageTypeSelectButton
                    onClick={() => selectMessageType(type.key)}
                  >
                    <MessageTypeSelectButtonImg
                      src={`https://omiseconnect-assets.s3.ap-northeast-1.amazonaws.com/images/icon/push_message_object_type/${type.key}.png`}
                    />
                    <MessageTypeSelectButtonText>
                      {type.label}
                    </MessageTypeSelectButtonText>
                  </MessageTypeSelectButton>
                </div>
              ))}
            </div>
          </div>
        )}
        {messageObject.type === 'text' && (
          <div>
            <MessageTextObjectInput
              availableKeywords={availableKeywords}
              onChange={text => {
                messageObject.text = text
                onChange(messageObject)
              }}
              defaultValue={messageObject.text}
            />
          </div>
        )}
        {(messageObject.type === 'image' ||
          messageObject.type === 'imageWithLink') && (
          <div className="uk-padding-small">
            <div className="uk-form-label uk-margin-small-bottom">
              表示する画像
              <span className="required-icon">必須</span>
            </div>
            {messageObject.imageSrc ? (
              <div className="uk-width-3-4@s uk-margin-auto uk-text-center">
                <div
                  className="uk-background-contain uk-height-small uk-panel"
                  style={{ backgroundImage: `url(${messageObject.imageSrc})` }}
                />
                <div
                  className="uk-button uk-button-default uk-button-small uk-margin-top"
                  onClick={() => changeObjectFile(null)}
                >
                  画像を変更する
                </div>
              </div>
            ) : (
              <ImageDropInput onChange={file => changeObjectFile(file)} />
            )}
            {messageObject.type === 'imageWithLink' && (
              <div className="uk-margin-top uk-margin-small-bottom">
                <div className="uk-margin-bottom">
                  <label className="uk-form-label">タイトル</label>
                  <span
                    uk-icon="question"
                    className="hint--top hint--large hint--rounded"
                    aria-label="先頭の吹き出しの場合には、プッシュ通知画面に表示されます。また最後の吹き出しの場合には、チャットリスト画面に表示されます。"
                    style={{ width: 16, marginLeft: 5 }}
                  />
                  {messageObject.altText &&
                    messageObject.altText.length > 100 && (
                      <div className="uk-text-xsmall uk-text-danger uk-margin-small-top">
                        タイトルは100文字以内で入力してください。
                      </div>
                    )}
                  <input
                    className="uk-input uk-form-small"
                    type="text"
                    value={messageObject.altText || ''}
                    onChange={e => {
                      messageObject.altText = e.target.value
                      onChange(messageObject)
                    }}
                    style={{ marginTop: 4 }}
                  />
                </div>
                <label className="uk-form-label">
                  画像タップで開くページ
                  <span className="required-icon">必須</span>
                </label>
                <span
                  uk-icon="question"
                  className="hint--top hint--large hint--rounded"
                  aria-label="お客さまが画像をタップした際に、登録したURLのページを開きます"
                  style={{ width: 16, marginLeft: 5 }}
                />
                <div className="uk-flex uk-flex-between uk-flex-bottom">
                  <div className="uk-text-xsmall uk-text-danger uk-margin-small-top">
                    {messageObject.uri &&
                    !messageObject.uri.match(
                      /^https?:\/\/[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#]+/
                    )
                      ? '※ URLの形式が正しくない可能性があります'
                      : ''}
                  </div>
                  {(!messageObject.uri || messageObject.uri == '') && (
                    <InsertShopUrlButton
                      className="uk-button uk-button-default"
                      onClick={() => {
                        messageObject.uri = company.shopAccount.shopUrl
                        onChange(messageObject)
                      }}
                    >
                      ショップのURLを挿入する
                    </InsertShopUrlButton>
                  )}
                </div>
                <InputWrapper>
                  <input
                    className="uk-input uk-form-small"
                    type="text"
                    value={messageObject.uri || ''}
                    onChange={e => {
                      messageObject.uri = e.target.value
                      onChange(messageObject)
                    }}
                    placeholder="https://example.com/..."
                    style={{ marginTop: 4 }}
                  />
                  {messageObject.uri && messageObject.uri != '' && (
                    <ResetImageUrlButton
                      onClick={() => {
                        messageObject.uri = ''
                        onChange(messageObject)
                      }}
                    >
                      <CancelIcon style={{ fontSize: 18 }} />
                    </ResetImageUrlButton>
                  )}
                </InputWrapper>
              </div>
            )}
          </div>
        )}
        {messageObject.type !== null && !messageObject.id && (
          <div className="uk-text-right uk-text-xsmall">
            <a onClick={resetMessageType}>吹き出しの種類を変更する</a>
          </div>
        )}
      </div>
    </div>
  )
}
export default MessageObjectFormCard

const MessageObjectHeaderIcon = styled.span`
  cursor: pointer;
  margin-left: 8px;
`
const MessageTypeSelectButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100px;
  color: #00888d;
  font-size: 0.85rem;
  border: 1px solid;
  border-radius: 5px;
  cursor: pointer;
  @media screen and (max-width: 600px) {
    flex-direction: row;
    height: 48px;
    margin-bottom: 15px !important;
  }
`
const MessageTypeSelectButtonImg = styled.img`
  width: 32px;
  @media screen and (max-width: 600px) {
    width: 28px;
  }
`
const MessageTypeSelectButtonText = styled.div`
  margin-top: 10px;
  @media screen and (max-width: 600px) {
    margin: 0;
    width: 60%;
  }
`
const InputWrapper = styled.div`
  position: relative;
`
const InsertShopUrlButton = styled.div`
  font-size: 12px;
  line-height: 28px;
  padding: 0 12px;
  margin-top: 5px;

  @media screen and (max-width: 600px) {
    font-size: 10px;
    line-height: 22px;
    padding: 0 6px;
    margin-top: 10px;
  }
`
const ResetImageUrlButton = styled.div`
  position: absolute;
  right: 6px;
  top: 7px;
  opacity: 0.5;
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
`
